<template>
  <div>
    <div class="topImg">
      <img src="@/assets/image/img/30.png" />
    </div>
    <div class="content flex_space_between">
      <div class="left">
        <div class="left_main">
          <div class="per flex_direction_column">
            <img :src="$store.state.userInfo.avatar" />
            <span>{{ $store.state.userInfo.nick_name }}</span>
            <div class="edit flex" @click="isSetUp = !isSetUp">
              <img src="@/assets/image/icon/20.png" />
              <p>编辑</p>
            </div>
          </div>
          <div class="nav flex_direction_column">
            <div
              class="item flex_center"
              :class="{ navActive: index == navIndex }"
              v-for="(item, index) in navList"
              :key="index"
              @click="navClick(item, index)"
            >
              <img src="@/assets/image/icon/18.png" />
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right_main">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <SetUpPopup :isSetUp="isSetUp" @close="close"></SetUpPopup>
  </div>
</template>

<script>
import SetUpPopup from '@/components/popup/setup.vue'
export default {
  components: { SetUpPopup },
  data() {
    return {
      isSetUp: false,
      // navIndex: 0,
      navIndex: 0,
      navList: [
        {
          name: '我的赛事',
          url: '/mine-match'
        },
        // {
        //   name: '我的活动',
        //   url: '/mine-activity'
        // },
        {
          name: '个人信息',
          url: '/mine-info'
        },
        {
          name: '修改密码',
          url: '/mine-alter'
        }
      ]
    }
  },

  mounted() {},

  methods: {
    navClick(item, index) {
      this.navIndex = index
      this.$router.push(item.url)
    },
    close() {
      this.isSetUp = false
    }
  },
  watch: {
    $route: {
      handler(val) {
        this.navIndex = val.meta.asideIndex
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.topImg {
  width: 100%;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.content {
  margin-top: 20px;
  margin-bottom: 18px;
  align-items: flex-start;
}
.left {
  width: 280px;
  border-radius: 3px;
  background-color: #fff;
  .left_main {
    padding: 34px 23px 26px 23px;
    .per {
      align-items: center;
      padding-bottom: 21px;
      justify-content: center;
      border-bottom: 1px solid #eeeeee;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
      }
      span {
        height: 28px;
        margin-top: 13px;
        font-weight: bold;
        line-height: 28px;
        font-size: @font_size_20;
      }
      .edit {
        width: 102px;
        height: 30px;
        cursor: pointer;
        margin-top: 13px;
        border-radius: 3px;
        justify-content: center;
        background-color: @color_bg_two;
        img {
          width: 15px;
          height: 14px;
          border-radius: 0;
        }
        p {
          margin-left: 5px;
          color: @color_seven;
          font-size: @font_size_14;
        }
      }
    }
    .navActive {
      color: #fff;
      background-color: @color_one;
    }
    .nav {
      .item {
        width: 234px;
        height: 50px;
        cursor: pointer;
        line-height: 50px;
        border-radius: 3px;
        margin-bottom: 20px;
        border: 1px solid #eeeeee;
        &:nth-child(1) {
          margin-top: 22px;
        }
        img {
          width: 10px;
          height: 8px;
          margin-right: 15px;
        }
        span {
          font-size: @font_size_18;
        }
      }
    }
  }
}
.right {
  width: 1060px;
  border-radius: 3px;
  background-color: #fff;
  .right_main {
    min-height: 530px;
    padding: 20px 24px 26px 24px;
  }
}
</style>
